<template>
  <b-modal
    v-model="modalApp"
    size="lg"
    title-class="h3 text-white"
    :title="`App for ${name_client}`"
    hide-footer
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    header-class="p-0"
    scrollable
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          App for {{ name_client }}
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="close"
          />
        </div>
      </div>
    </template>
    <div>
      <b-row class="align-items-center">
        <b-col cols="5">
          <div style="font: size 0.8rem">
            <span>Status in the app: </span>
            <div
              :class="`badge badge-${
                status === 'Active' ? 'success' : 'warning'
              }`"
            >
              <span class="text-bold">{{ status }}</span>
            </div>
          </div>
        </b-col>
        <b-col cols="7">
          <div style="font: size 0.8rem" class="inline-block">
            <span>Client password:</span>
            <span class="text-bold mx-1">{{ password }}</span>
            <b-button
              class="btn-sm"
              variant="outline-info"
              @click="toogleChangePassword"
            >
              Alter password
              <feather-icon icon="KeyIcon" />
            </b-button>
          </div>
        </b-col>
      </b-row>
      <div v-if="showAlterPassword">
        <ValidationObserver ref="form">
          <b-row>
            <b-col>
              <ValidationProvider v-slot="{ errors }" rules="required">
                <b-form-group>
                  <template #label> New Password </template>
                  <b-form-input
                    v-model="newPassword"
                    type="password"
                    placeholder="Password"
                    :class="{ 'border border-danger': errors[0] }"
                    required
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col>
              <div class="d-flex flex-column">
                <label style="height: 19.532px" />
                <b-button
                  class="btn rounded"
                  variant="outline-success"
                  @click="changePassword"
                >
                  {{ password ? "Change" : "Set" }} password
                  <feather-icon icon="KeyIcon" />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </ValidationObserver>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  props: {
    account_id: {
      type: String,
      required: true,
    },
    name_client: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalApp: false,
      apiApp: process.env.VUE_APP_BASE_APP_URL,
      dataApp: {},
      status: "",
      password: "Don' t have password",
      newPassword: "",
      showAlterPassword: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    this.modalApp = true;
    await this.getStatusClient();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async getStatusClient() {
      axios
        .post(`${this.apiApp}/client/account`, {
          account_id: this.account_id,
        })
        .then((response) => {
          this.dataApp = response.data;
          this.status =
            this.dataApp[0]?.date_activation != null ? "Active" : "Inactive";
          this.password = this.dataApp[0]?.password;
        });
    },

    async trackingPassword() {
      try {
        await axios.post(`${this.apiApp}/client/tracking-password`, {
          account_id: this.account_id,
          password: this.newPassword,
          created_by: this.currentUser.user_id,
          updated_by: this.currentUser.user_id,
        });
      } catch (error) {
        console.log(error);
      }
    },
    toogleChangePassword() {
      this.showAlterPassword = !this.showAlterPassword;
    },
    async changePassword() {
      const validate = await this.$refs.form.validate();
      if (validate) {
        const swal = await this.showConfirmSwal(
          "Are you sure?",
          "You want to change the password?",
          "warning",
          "Yes, change it!"
        );
        if (swal.isConfirmed) {
          try {
            const response = await axios.post(
              `${this.apiApp}/client/alter-password`,
              {
                account_id: this.account_id,
                password: this.newPassword,
              }
            );
            if (response.status === 200) {
              await this.trackingPassword();
              this.password = this.newPassword;
              this.newPassword = "";
              this.showAlterPassword = false;
              this.showSuccessSwal(
                "Success",
                "The password was changed successfully",
                "success"
              );
            } else {
              this.showErrorSwal(
                "Error",
                "The password was not changed",
                "error"
              );
            }
          } catch (error) {
            this.showErrorSwal(
              "Error",
              "The password was not changed",
              "error"
            );
          }
        }
      }
    },
  },
};
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.badge-info {
  background-color: #17a2b8;
  color: #fff;
  padding: 0.2rem 1rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: bold;
  display: inline-block;
  margin-left: 0.5rem;
}
.margin-custom fieldset {
  margin: auto 0;
}
</style>
