<template>
  <b-modal
    v-model="ownModal"
    title="History Of Password"
    title-tag="h3"
    size="lg"
    hide-footer
    header-class="p-0"
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    scrollable
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          History Of Password
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="close"
          />
        </div>
      </div>
    </template>
    <account-client-header-deb :client="client" />
    <b-table
      :items="trackingPassword"
      :fields="fields"
      show-empty
      class="table-new-customization custom-table"
      sticky-header="50vh"
    >
      <template #cell(password)="data">
        {{ data.item.password }}
      </template>
      <template #cell(created_by)="data">
        {{ data.item.created_by }} <br />{{
          data.item.created_at | myGlobalDay
        }}
      </template>
      <template #cell(updated_by)="data">
        {{ data.item.updated_by }} <br />{{
          data.item.updated_at | myGlobalDay
        }}
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import AccountClientHeaderDeb from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/AccountClientHeaderDeb.vue";
import axios from "axios";

export default {
  components: {
    AccountClientHeaderDeb,
  },

  data() {
    return {
      history: [],
      apiApp: process.env.VUE_APP_BASE_APP_URL,
      ownModal: false,
      fields: [
        { key: "password", label: "Password", class: "w-25" },
        { key: "created_by", label: "Created By" },
        { key: "updated_by", label: "Updated By" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
    }),
  },
  created() {
    this.ownModal = true;
  },

  methods: {
    async trackingPassword() {
      try {
        const data = await axios.post(
          `${this.apiApp}/client/get-tracking-password`,
          {
            client_id: this.$route.params.idClient,
          }
        );
        this.history = data.data.data;

        return this.history;
      } catch (error) {
        console.log(error);
      }
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.info-container {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
</style>
