<template>
  <div>
    <b-modal
      modal
      title="INFORMATION"
      v-model="openModalInformation"
      size="xmd"
      modal-class="custom-modal-amg"
      hide-footer
      @hidden="close"
      header-bg-variant="transparent"
      header-class="p-0"
      title-tag="h3"
      scrollable
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            Information
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="close"
            />
          </div>
        </div>
      </template>
      <div v-for="item in data" :key="item.program_id">
        <div>
          <b-card-group deck align="center">
            <b-card header="CLIENT">
              <b-card-body
                body-border-variant="primary"
                class="padding-5-px rounded"
              >
                {{ item.client_name }}
              </b-card-body>
            </b-card>
            <b-card header="ACCOUNT">
              <b-card-body
                body-border-variant="primary"
                class="padding-5-px rounded"
              >
                {{ item.account }}
              </b-card-body>
            </b-card>
            <b-card header="PROGRAM">
              <b-card-body
                body-border-variant="primary"
                class="padding-5-px rounded"
              >
                {{ item.name_program }}
              </b-card-body>
            </b-card>
            <b-card
              header="TOTAL PAYMENTS"
              v-if="![3, 4].includes(item.program_id) && item.program_id != 1"
            >
              <b-card-body
                body-border-variant="primary"
                class="padding-5-px rounded"
              >
                $ {{ item.total_payment }}
              </b-card-body>
            </b-card>
            <b-card header="PACK" v-if="item.program_id == 1">
              <b-card-body
                body-border-variant="primary"
                class="padding-5-px rounded"
              >
                {{ !item.pack_others ? "Others" : item.pack }}
                {{ item.fee_amount ? "( $ " + item.fee_amount + " )" : "" }}
              </b-card-body>
            </b-card>
          </b-card-group>
          <b-card-group
            deck
            align="center"
            v-if="[3, 4].includes(item.program_id)"
          >
            <b-card header="TOTAL FEES AND CHARGES">
              <b-card-body
                body-border-variant="primary"
                class="padding-5-px rounded"
              >
                $ {{ item.total_charge }}
              </b-card-body>
            </b-card>
            <b-card header="TOTAL PAYMENTS">
              <b-card-body
                body-border-variant="primary"
                class="padding-5-px rounded"
              >
                $ {{ item.total_payment }}
              </b-card-body>
            </b-card>
            <b-card header="ACCOUNT BALANCE">
              <b-card-body
                body-border-variant="primary"
                class="padding-5-px rounded"
                :style="{
                  color: item.balance < 0 ? 'red' : null,
                }"
              >
                $ {{ item.balance }}
              </b-card-body>
            </b-card>
          </b-card-group>
        </div>
        <div>
          <b-card-body>
            <b-tabs content-class="mt-3">
              <b-tab title="NOTES" active>
                <div style="max-height: 600px !important; overflow-y: scroll">
                  <b-table
                    striped
                    hover
                    class="custom-table table-new-customization"
                    :items="JSON.parse(item.notes)"
                    :fields="fieldsNotes"
                  >
                    <template #cell(content)="data">
                      <div v-html="data.item.content"></div>
                    </template>

                    <template #cell(date_note)="data">
                      {{ data.item.date_note | myGlobal }}
                    </template>

                    <template #cell(created_by)="data">
                      {{ data.item.created_by }}
                      <br />
                      {{ data.item.created_at | myGlobalWithHour }}
                    </template>
                  </b-table>
                </div>
              </b-tab>
              <b-tab title="PAYMENTS">
                <div>
                  <b-table
                    striped
                    hover
                    class="custom-table table-new-customization"
                    :items="JSON.parse(item.payments)"
                    :fields="fieldsPayments"
                  >
                    <template #cell(date_payment)="data">
                      {{ data.item.settlement_date | myGlobal }}
                    </template>

                    <template #cell(type_transaction)="data">
                      {{ data.item.type }}
                    </template>

                    <template #cell(transaction_id)="data">
                      {{ data.item.transaction_id }}
                    </template>

                    <template #cell(amount)="data">
                      <money
                        v-model="data.item.amount"
                        v-bind="{
                          decimal: '.',
                          thousands: ',',
                          prefix: '$ ',
                          precision: 2,
                        }"
                        class="form-control"
                        style="
                          border: none;
                          background: none;
                          opacity: 1;
                          margin-top: -8px;
                        "
                        disabled
                      />
                    </template>

                    <template #cell(method)="data">
                      {{ data.item.method_payment }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <div
                        :style="`color: ${data.item.color}; cursor: ${
                          data.item.status_id != 4 ? `auto` : `pointer`
                        }`"
                      >
                        <feather-icon
                          v-if="data.item.status_id == 4"
                          icon="RefreshCcwIcon"
                          style="margin-right: 0.1rem"
                          size="15"
                        />
                        {{
                          [6, 14].includes(data.item.type_transaction_id) &&
                          data.item.status_id == 3
                            ? "Pending"
                            : data.item.status
                        }}
                      </div>
                    </template>
                  </b-table>
                </div>
              </b-tab>
              <b-tab title="CREDITORS" v-if="item.program_id == 4">
                <div>
                  <b-table
                    striped
                    hover
                    class="custom-table table-new-customization"
                    :items="JSON.parse(item.creditors)"
                    :fields="fieldsCreditors"
                  >
                    <template #cell(original_creditor)="data">
                      <code>{{ data.item.original_creditor }}</code>
                      <br />
                      {{ data.item.account }}
                    </template>

                    <template #cell(initial_balance)="data">
                      <money
                        v-model="data.item.initial_balance"
                        v-bind="{
                          decimal: '.',
                          thousands: ',',
                          prefix: '$ ',
                          precision: 2,
                        }"
                        class="form-control"
                        style="
                          border: none;
                          background: none;
                          opacity: 1;
                          margin-top: -8px;
                        "
                        disabled
                      />
                    </template>

                    <template #cell(actual_balance)="data">
                      <money
                        v-model="data.item.actual_balance"
                        v-bind="{
                          decimal: '.',
                          thousands: ',',
                          prefix: '$ ',
                          precision: 2,
                        }"
                        class="form-control"
                        style="
                          border: none;
                          background: none;
                          opacity: 1;
                          margin-top: -8px;
                        "
                        disabled
                      />
                    </template>
                  </b-table>
                </div>
              </b-tab>
            </b-tabs>
          </b-card-body>
        </div>
      </div>
    </b-modal>
  </div>
</template>
// Reyna Aguirre

<script>
// Import Components
import ClientDashboardService from "../../services/clients.dashboard.services";

export default {
  props: {
    client_account_id: {
      type: String,
    },
  },
  data() {
    return {
      openModalInformation: true,
      data: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "S/ ",
        precision: 2,
        masked: false,
      },
      fieldsNotes: [
        {
          key: "content",
          sortable: false,
          thStyle: { width: "50%" },
        },
        {
          key: "type_note",
          sortable: false,
        },
        {
          key: "date_note",
          sortable: false,
        },
        {
          key: "created_by",
          sortable: false,
        },
      ],
      fieldsPayments: [
        {
          key: "date_payment",
          sortable: false,
        },
        {
          key: "type_transaction",
          sortable: false,
        },
        {
          key: "transaction_id",
          sortable: false,
        },
        {
          key: "amount",
          sortable: false,
          thStyle: { width: "15%" },
        },
        {
          key: "method",
          sortable: false,
        },
        {
          key: "status",
          sortable: false,
        },
      ],
      fieldsCreditors: [
        {
          key: "original_creditor",
          sortable: false,
          thStyle: { width: "20%" },
        },
        {
          key: "initial_balance",
          sortable: false,
        },
        {
          key: "actual_balance",
          sortable: false,
        },
        {
          key: "months_behind",
          sortable: false,
        },
        {
          key: "account_status",
          sortable: false,
        },
        {
          key: "account_phase",
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    close() {
      this.$emit("clickCloseModalInformation");
    },
    async getData() {
      try {
        this.addPreloader();
        const response = await ClientDashboardService.getOthersProgramsClients({
          id_account: this.client_account_id,
        });
        if (response.status == 200) {
          this.removePreloader();
          this.data = response.data;
        }
      } catch (error) {
        this.removePreloader();
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with Programs!"
        );
      }
    },
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.padding-5-px {
  padding: 5px;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
</style>
