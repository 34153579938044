<template>
  <div>
    <b-modal
      modal
      title="PROGRAM LIST"
      v-model="openModalprogramList"
      size="xmd"
      modal-class="custom-modal-amg"
      hide-footer
      header-bg-variant="transparent"
      @hidden="closeModalProgramList"
      header-class="p-0"
      title-tag="h3"
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            Program List
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="closeModalProgramList"
            />
          </div>
        </div>
      </template>

      <!-- Table -->
      <b-table
        small
        ref="refClientsList"
        :items="accounts"
        :fields="fields"
        primary-key="id"
        table-class="text-nowrap"
        responsive="sm"
        show-empty
        sticky-header="70vh"
        :busy="isBusy"
        class="table-new-customization custom-table"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading ...</strong>
          </div>
        </template>
        <!-- status -->
        <template #cell(status)="data">
          <status-account :account="data.item" />
        </template>
        <!-- charges -->
        <template #cell(charges)="data">
          <span v-if="data.item.charges != null"
            >$ {{ data.item.charges }}</span
          >
        </template>
        <!-- state_advisor -->
        <template #cell(state_advisor)="data">
          <div>
            <feather-icon
              id="icon"
              icon="CircleIcon"
              size="13"
              :style="
                data.item.state_advisor == 1
                  ? 'background:green'
                  : data.item.state_advisor == 2
                  ? 'background:orange'
                  : data.item.state_advisor == 3
                  ? 'background:red'
                  : 'background:#ccc'
              "
            />
            {{ data.item.advisor }}
          </div>
        </template>
        <!-- creation_date -->
        <template #cell(creation_date)="data">
          <div>
            <span>{{ data.item.creation_date | myGlobal }}</span>
          </div>
        </template>
        <template #cell(view)="data">
          <div title="More information">
            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer ml-1"
              size="16"
              @click="clickModalInformation(data.item)"
            />
          </div>
        </template>
      </b-table>
    </b-modal>
    <modal-share-information
      v-if="openModalInformation"
      :client_account_id="client_account_id"
      @clickCloseModalInformation="clickCloseModalInformation"
    />
  </div>
</template>

<script>
import ModalShareInformation from "@/views/commons/components/clients/dashboard/information-client/modals/ModalShareInformation.vue";

// import Services
import ClientDashboardService from "../../services/clients.dashboard.services";
// import Components
import StatusAccount from "../../../stylescomponents/StatusAccount.vue";
export default {
  components: {
    StatusAccount,
    ModalShareInformation,
  },
  props: {
    modalProgramList: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      openModalprogramList: this.modalProgramList,
      isBusy: false,
      accounts: [],
      fields: [
        { key: "program", label: "Program" },
        { key: "name", label: "Name" },
        { key: "account", label: "Account" },
        { key: "status", label: "Status" },
        { key: "charges", label: "Fee & Charges" },
        { key: "state_advisor", label: "Advisor" },
        { key: "creation_date", label: "Creation Date" },
        { key: "view", label: "ACTIONS" },
      ],
      openModalInformation: false,
      client_account_id: null,
    };
  },
  methods: {
    closeModalProgramList() {
      this.$emit("closeModalProgramList");
    },
    searchProgramList: async function () {
      try {
        this.isBusy = true;
        const response = await ClientDashboardService.searchProgramList({
          id_account: this.$route.params.idClient,
        });
        if (response.status == 200) {
          this.accounts = response.data;
          this.isBusy = false;
        }
      } catch (error) {
        this.isBusy = false;
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with Programs!"
        );
      }
    },
    clickModalInformation(data) {
      this.client_account_id = data.client_account_id;
      this.openModalInformation = true;
    },
    clickCloseModalInformation() {
      this.openModalInformation = false;
    },
  },
  created() {
    this.searchProgramList();
  },
};
</script>

<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
</style>
