<template>
  <div>
    <header-slot :clients-search="true">
      <template #actions>
        <div class="d-flex justify-content-end">
          <court-info-action />
          <b-button-group v-if="client.program_id == 3">
            <b-button size="sm" variant="primary" @click="openModalApp">
              <div class="d-flex justify-content-center align-items-center">
                <amg-icon icon="CustomAppIcon" class="mr-1" size="20" />
                <span class="font-small-5">App</span>
              </div>
            </b-button>
            <b-button
              v-b-tooltip.hover.top="`History Password`"
              variant="primary"
              @click="openTrackingChangePassword"
            >
              <feather-icon icon="MenuIcon" />
            </b-button>
          </b-button-group>

          <b-dropdown
            :disabled="isResponsibleAccount"
            class="ml-1"
            right
            text="Add Service"
          >
            <template #button-content>
              <feather-icon icon="PlusIcon" />
              Add Services
            </template>
            <b-dropdown-item @click="openAddService(3)">
              Add Service
            </b-dropdown-item>
            <b-dropdown-item @click="openAddService(4)">
              Change Service
            </b-dropdown-item>
          </b-dropdown>

          <b-button
            :disabled="client != null ? client.count_program == 0 : ''"
            class="ml-1"
            @click="openModalProgramList"
          >
            <feather-icon icon="MonitorIcon" />
            Others Programs
          </b-button>

          <b-dropdown
            id="dropdown-1"
            class="ml-1"
            :disabled="isResponsibleAccount"
            variant="success"
          >
            <template #button-content>
              <feather-icon icon="FileTextIcon" />
              Request CR
              <b-badge
                v-if="countNcrForUpdate > 0"
                v-b-tooltip.hover
                title="The specialist requests a new NCR because it has already expired"
                variant="info"
                >{{ countNcrForUpdate }}</b-badge
              >
            </template>
            <b-dropdown-item @click="openModalCreateNcr('AUTOMATIC')">
              Automatic
            </b-dropdown-item>
            <b-dropdown-item @click="openModalCreateNcr('MANUAL')">
              Manual
            </b-dropdown-item>
          </b-dropdown>

          <b-button
            class="ml-1"
            @click="modalLetterOn = true"
            :disabled="!isCe || isResponsibleAccount"
          >
            <feather-icon icon="SendIcon" />
            Send Letter
          </b-button>
        </div>
      </template>
    </header-slot>

    <b-card no-body class="m-0">
      <div class="m-3">
        <b-row>
          <b-col lg="auto">
            <b-skeleton-img v-if="!validateImg" width="80px" height="80px" />
            <b-img
              v-if="validateImg"
              :src="getProgramLogo('Quality')"
              thumbnail
              fluid
              style="max-width: 80px; max-height: 80px"
            />
          </b-col>
          <b-col lg="auto">
            <b-skeleton-wrapper :loading="!validateAccount">
              <template #loading>
                <b-card no-body>
                  <b-skeleton width="85%" style="min-width: 124px" />
                  <b-skeleton width="55%" style="min-width: 95px" />
                  <b-skeleton width="70%" style="min-width: 110px" />
                </b-card>
              </template>
            </b-skeleton-wrapper>
            <b-form-group style="margin-bottom: 5px !important">
              <template v-if="validateAccount">
                <span
                  style="font-weight: bolder; font-size: 17.5px"
                  class="cursor-pointer"
                  @click="openEditLead"
                  >{{ client.client_name }}</span
                >
                <span
                  v-if="
                    $store.getters[
                      'NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'
                    ].is_responsible
                  "
                  class="text-primary"
                >
                  <tabler-icon
                    :icon="
                      $store.getters[
                        'NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'
                      ].is_responsible === currentUser.user_id
                        ? 'StarIcon'
                        : 'LockIcon'
                    "
                    size="15"
                    style="margin-left: 5px"
                  />
                  <strong>CEO's client</strong>
                </span>
                <br />
              </template>
            </b-form-group>
            <b-form-group>
              <template v-if="validateAccount">
                <span style="font-size: 14.5px"
                  ><span
                    style="
                      font-weight: bolder;
                      font-size: 14px;
                      margin-right: 5px;
                    "
                    >Account:</span
                  >
                  {{ client.account }}</span
                >
                <br />

                <StatusClientAccount />
              </template>
            </b-form-group>
            <efectivity-client :client="client" />
          </b-col>
          <b-col v-if="screenWidth > 1400" lg="3" />
          <b-col v-if="client.track_finish == null ? false : true" lg="1" />

          <b-col lg="2" />
          <b-col
            v-if="client.track_id != null"
            style="margin: 0px; padding: 0px"
            lg="auto"
          >
            <span
              >TRACKING: {{ client.tracking_start }} -
              {{ client.tracking_end }}</span
            >
            <b-row>
              <b-col lg="auto">
                <b-input-group prepend="FILE">
                  <b-input-group-append>
                    <div
                      :style="isDarkSkin ? 'opacity: 0.6' : ''"
                      class="form-group-input2 d-flex align-content-center align-items-center"
                    >
                      <feather-icon
                        v-if="client.file_status == null"
                        class="text-secondary cursor-pointer"
                        size="21"
                        icon="CheckCircleIcon"
                        @click="checkstatus(client.track_id, 1, 1)"
                      />
                      <feather-icon
                        v-else-if="client.file_status != null"
                        v-b-tooltip.hover.left="client.file_status"
                        class="cursor-pointer text-success"
                        size="21"
                        icon="CheckCircleIcon"
                        @click="checkstatus(client.track_id, 1, 2)"
                      />
                    </div>
                  </b-input-group-append> </b-input-group
              ></b-col>
              <b-col lg="auto">
                <b-input-group prepend="CALL">
                  <b-input-group-append>
                    <div
                      :style="isDarkSkin ? 'opacity: 0.6' : ''"
                      class="form-group-input2 d-flex align-content-center align-items-center"
                    >
                      <div class="text-center">
                        <b-badge
                          v-if="client.track_finish == null ? true : false"
                          class="cursor-pointer"
                          variant="danger"
                          @click="
                            checkstatus(
                              client.track_id,
                              2,
                              1,
                              client.dates_calls
                            )
                          "
                        >
                          <feather-icon icon="MinusIcon" />
                        </b-badge>
                        <b-badge
                          :id="'call-target-' + client.track_id"
                          variant="primary"
                          style="
                            width: 40px;
                            margin-left: 0.3rem;
                            margin-right: 0.3rem;
                          "
                        >
                          {{ client.call_status }}
                        </b-badge>
                        <b-tooltip
                          :key="cont"
                          v-if="
                            (JSON.parse(client.dates_calls)
                              ? JSON.parse(client.dates_calls).length
                              : 0) > 0
                          "
                          :target="'call-target-' + client.track_id"
                          triggers="hover"
                          placement="left"
                        >
                          <div>
                            <span
                              v-for="(item, i) in JSON.parse(
                                client.dates_calls
                              )"
                              :key="i"
                            >
                              {{ item }}<br />
                            </span>
                          </div>
                        </b-tooltip>
                        <b-badge
                          v-if="client.track_finish == null ? true : false"
                          class="cursor-pointer"
                          variant="success"
                          @click="
                            checkstatus(
                              client.track_id,
                              2,
                              2,
                              client.dates_calls
                            )
                          "
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-badge>
                      </div>
                    </div>
                  </b-input-group-append> </b-input-group
              ></b-col>
              <b-col lg="auto">
                <b-input-group prepend="SMS">
                  <b-input-group-append>
                    <div
                      :style="isDarkSkin ? 'opacity: 0.6' : ''"
                      class="form-group-input2 d-flex align-content-center align-items-center"
                    >
                      <div class="text-center">
                        <b-badge
                          v-if="client.track_finish == null ? true : false"
                          variant="danger"
                          class="cursor-pointer"
                        >
                          <feather-icon
                            icon="MinusIcon"
                            @click="
                              checkstatus(
                                client.track_id,
                                3,
                                1,
                                client.dates_sms
                              )
                            "
                          />
                        </b-badge>
                        <b-badge
                          :id="'sms-target-' + client.tracking_id"
                          variant="primary"
                          style="
                            width: 40px;
                            margin-left: 0.3rem;
                            margin-right: 0.3rem;
                          "
                        >
                          {{ client.sms_status }}
                        </b-badge>
                        <b-tooltip
                          :key="cont"
                          v-if="
                            (JSON.parse(client.dates_sms)
                              ? JSON.parse(client.dates_sms).length
                              : 0) > 0
                          "
                          :target="'sms-target-' + client.tracking_id"
                          triggers="hover"
                          placement="left"
                        >
                          <div>
                            <span
                              v-for="(item, i) in JSON.parse(client.dates_sms)"
                              :key="i"
                            >
                              {{ item }}<br />
                            </span>
                          </div>
                        </b-tooltip>
                        <b-badge
                          v-if="client.track_finish == null ? true : false"
                          variant="success"
                          class="cursor-pointer"
                          @click="
                            checkstatus(client.track_id, 3, 2, client.dates_sms)
                          "
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-badge>
                      </div>
                    </div>
                  </b-input-group-append> </b-input-group
              ></b-col>
              <b-col lg="auto" class="d-flex align-items-center">
                <b-button
                  v-if="client.track_finish == null ? true : false"
                  :disabled="
                    !(
                      client.call_status > 0 &&
                      client.file_status != null &&
                      client.sms_status > 0
                    )
                  "
                  size="sm"
                  variant="primary"
                  @click="finishTracking(client.track_id)"
                >
                  <feather-icon
                    class="mr-1"
                    icon="CheckCircleIcon"
                  />FINISH</b-button
                ></b-col
              >
            </b-row>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-button
                :disabled="isResponsibleAccount"
                variant="info"
                @click="openModalTrackingHistoryClaims"
              >
                CLAIMS
                <b-badge
                  v-if="totalClaims > 0"
                  variant="danger"
                  class="rounded-pill"
                >
                  {{ totalClaims }}
                </b-badge>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-nav pills class="ml-3 mb-0">
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Personal Information'"
          :to="{ name: 'personal-information-quality' }"
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          exact
          >Personal Information
        </b-nav-item>

        <b-nav-item
          v-b-tooltip.hover.bottomright="'Payments'"
          :to="{ name: 'pay-quality' }"
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Pay</b-nav-item
        >
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Files'"
          :to="{ name: 'client-files-quality' }"
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Files
          <span
            v-if="countfileclient > 0"
            class="position-absolute small top-0 start-100 translate-middle badge rounded-pill bg-danger"
            style="
              font-size: 90% !important;
              margin-left: 68px;
              margin-top: -2px;
            "
          >
            {{ countfileclient }}</span
          >
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Access Credentials'"
          :to="{ name: 'acr-quality' }"
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >ACr</b-nav-item
        >
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Access Credentials'"
          :to="{ name: 'dis-quality' }"
          :active="isActiveDispute"
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Credit
          <b-badge v-if="creditCounter > 0" pill variant="danger" class="ml-1">
            {{ creditCounter }}
          </b-badge>
          <span v-if="client.missing_files > 0" class="counter-style ml-1">
            <feather-icon
              icon="AlertCircleIcon"
              class="text-white"
              size="15"
              v-b-tooltip.hover.top="'Missing Files'"
            />
          </span>
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Accounts'"
          exact
          v-if="client.program_id == 4"
          :to="{ name: 'acc-quality' }"
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >ACC
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Call Log'"
          :to="{ name: 'call-log-client-quality' }"
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          :active="isCallLog"
        >
          Call Log
        </b-nav-item>
      </b-nav>
      <b-card class="border-top-primary border-3 border-table-radius px-0">
        <router-view />
      </b-card>
    </b-card>

    <!-- Header Modals -->
    <lead-update
      v-if="isAddUpdateUserSidebarActive"
      :is-add-new-user-sidebar-active.sync="isAddUpdateUserSidebarActive"
      :lead="S_LEAD_EDIT"
      :type-edit="typeEdit"
      @refresh="refresh"
    />
    <modal-program-list
      v-if="modalProgramList"
      :modal-program-list="modalProgramList"
      @closeModalProgramList="closeModalProgramList"
    />

    <modal-history
      v-if="modalHistoryOn"
      :modal-history="modalHistoryOn"
      :name-program="client.program_name"
      :name-client="client.client_name"
      @closeModalHistory="closeModalHistory"
    />

    <modal-tracking-history-claims
      v-if="modalHistoryClaims"
      @close="closeModalTrackingHistoryClaims"
      :nClientAccountId="$route.params.idClient"
      :accountClient="client.account"
      :module="moduleClient"
    />

    <send-letter-modal v-if="modalLetterOn" @close="modalLetterOn = false" />

    <app-modal
      v-if="modalApp"
      :name_client="client.client_name"
      :account_id="$route.params.idClient"
      @close="closeModalApp"
    />

    <tracking-app-password
      v-if="activeModalHistoryPassword"
      @close="closeModalTrackingAppPassword"
    />

    <modal-create-ncr-request
      v-if="modalCreateNcr"
      :item="ncrParams"
      :ncr-type="selectedNcr"
      @close="closeModalCreateNcr"
      @reload="refresh"
    />

    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="modalOpenAddService = false"
      @changeProgram="openModalProgram"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
// Import Components
import LeadUpdate from "@/views/crm/views/Lead/lead-module/save/LeadUpdate.vue";
import ModalHistory from "@/views/commons/components/clients/dashboard/information-client/modals/ModalHistory.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import SendLetterModal from "@/views/boost-credit/views/clients/dashboard/information-client/modals/SendLetterModal.vue";
import ModalAddService from "@/views/commons/components/clients/modals/ModalAddService.vue";

// Import Modals
import ModalProgramList from "@/views/commons/components/clients/dashboard/information-client/modals/ModalProgramList.vue";
import ModalTrackingHistoryClaims from "@/views/commons/components/claims_v2/components/Modals/ModalTrackingHistoryClaims.vue";
import moment from "moment";
import trackingListService from "@/views/commons/components/tracking-list/service/tracking-list.service";
import AppModal from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/information-client/modals/AppModal.vue";
import TrackingAppPassword from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/information-client/modals/TrackingAppPassword.vue";
import ModalCreateNcrRequest from "@/views/commons/components/request-ncr/views/modals/ModalCreateNcrRequest.vue";
import BadgeRiskyClients from "@/views/commons/components/risky-clients/BadgeRiskyClients";
import EfectivityClient from "@/views/commons/components/clients/components/efectivity/EfectivityClient.vue";

// Impoet Service
import ClaimV2Service from "@/views/commons/components/claims_v2/services/claims-v2.service.js";
import StatusClientAccount from "@/views/administration/views/clients/components/StatusClientAccount.vue";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
import CourtInfoAction from "@/views/debt-solution/views/court-info/components/CourtInfoAction.vue";

export default {
  components: {
    ModalProgramList,
    LeadUpdate,
    ModalHistory,
    SendLetterModal,
    AppModal,
    TrackingAppPassword,
    ModalTrackingHistoryClaims,
    ModalCreateNcrRequest,
    BadgeRiskyClients,
    EfectivityClient,
    StatusClientAccount,
    ModalAddService,
    CourtInfoAction,
  },

  data() {
    return {
      cont: 0,
      validateAccount: false,
      validateImg: false,
      typeEdit: "lead",
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      modalShare: false,
      modalProgramList: false,
      isAddUpdateUserSidebarActive: false,
      editSidebar: "first",
      modalHistoryOn: false,
      statusstate: false,
      modalLetterOn: false,
      modalApp: false,
      activeModalHistoryPassword: false,
      modalHistoryClaims: false,
      totalClaims: 0,
      modalCreateNcr: false,
      selectedNcr: "",
      countNcrForUpdate: null,
      typeAddChange: null,
      modalOpenAddService: false,
      event: null,
      detailsClient: {},
      typeModal: null,
    };
  },
  computed: {
    isCallLog() {
      if (this.$route.matched[2]) {
        const { callConversationRoute, callNoConversationRoute } =
          this.$route.matched[2].meta;
        return [callConversationRoute, callNoConversationRoute].includes(
          this.$route.name
        );
      }
      return false;
    },

    isActiveDispute() {
      return this.$route.matched?.[2]?.name == "dis-quality";
    },
    statusDark() {
      return this.skin == "dark" ? "dark" : "";
    },
    ...mapState({
      S_LEAD_EDIT: (state) => state.CrmLeadStore.S_LEAD_EDIT,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      countdisputeclient: "DebtSolutionClients/G_COUNT_DISPUTE",
      countfileclient: "DebtSolutionClients/G_COUNT_FILES",
      G_COUNTER_ALL_SERVICES: "clients-store/G_COUNTER_ALL_SERVICES",
      cPendingNcrByClient: "RequestNcrStore/G_PENDING_BY_CLIENT",
      G_COUNT_PENDING_WP_CLIENT: "clients-store/G_COUNT_PENDING_WP_CLIENT",
      updates: "ReportStore/G_UPDATES_DISPUTE",
    }),
    creditCounter() {
      return (
        this.cPendingNcrByClient +
        this.G_COUNT_PENDING_WP_CLIENT +
        this.updates +
        this.G_COUNTER_ALL_SERVICES
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    moduleClient() {
      return this.client.module;
    },

    isCe() {
      return (
        [6, 22].includes(this.moduleId) || [6, 22].includes(this.client.module)
      );
    },
    isBc() {
      return [7].includes(this.moduleId) || [7].includes(this.client.module);
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
    ncrParams() {
      const {
        id,
        account,
        client_name: client,
        program_id,
        lead_id,
      } = this.client;
      const { user_id, role_id } = this.currentUser;
      return {
        client_account_id: id,
        id, // client_account_id
        account,
        program_id,
        lead_id,
        fullname: client, // full name
        user_id,
        role_id,
        type: "CLIENT",
        used_cards: JSON.stringify([]),
      };
    },
  },
  async created() {
    this.addPreloader();
    await this.$store.dispatch(
      "NotificationStore/A_ACCOUNT_IS_RESPONSIBLE_CEO",
      { account_id: this.$route.params.idClient }
    );
    try {
      await Promise.all([
        this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
          id: this.$route.params.idClient,
        }),
        this.$store.dispatch("DebtSolutionClients/A_COUNT_FILES", {
          idaccountclient: this.$route.params.idClient,
        }),
        this.$store.dispatch("DebtSolutionClients/A_COUNT_DISPUTE", {
          idaccountclient: this.$route.params.idClient,
        }),
        this.A_GET_ALL_SERVICES_COUNTER({
          client_account_id: this.$route.params.idClient,
          module_id: this.moduleId,
        }),
        this.A_COUNT_PENDING_WP_CLIENT({
          client_account_id: this.$route.params.idClient,
        }),
        this.A_GET_COUNTERS_BY_CLIENT(this.$route.params.idClient),
        this.getUpdates(),
      ]);
      this.removePreloader();
    } catch (error) {
      this.removePreloader();
      console.error(error);
    }
    this.validateImg = true;
    this.validateAccount = true;
    await this.getCounterClaims();
  },

  methods: {
    async finishTracking(id) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const data = await trackingListService.finishTrackingList({
            id,
          });
          if (data.status === 200) {
            this.cont++;
            this.client.track_finish = data.data[0].track_finish;

            this.showSuccessSwal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async checkstatus(id, type, status, datos) {
      try {
        if (this.client.track_finish == null) {
          const date = moment().format("MM/DD/YYYY hh:mm A");
          const array = datos == null ? [] : JSON.parse(datos);
          if (status === 2) {
            array.push(date);
          } else {
            array.pop();
          }

          const data = await trackingListService.updateStatusTracking({
            id,
            type,
            status,
            newval: array,
          });
          if (data.status === 200) {
            this.client.file_status = data.data[0].file_status;
            this.client.call_status = data.data[0].call_status;
            this.client.dates_calls = data.data[0].dates_calls;
            this.client.sms_status = data.data[0].sms_status;
            this.client.dates_sms = data.data[0].dates_sms;
          }
        }
      } catch (e) {}
    },
    async refresh() {
      await this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
        id: this.$route.params.idClient,
      });
    },
    openCloseModalHistory() {
      this.modalHistoryOn = true;
    },
    closeModalHistory() {
      this.modalHistoryOn = false;
    },

    ...mapActions({
      A_GET_LEAD_EDIT: "CrmLeadStore/A_GET_LEAD_EDIT",
      A_GET_PROGRAMS: "CrmGlobalStore/A_GET_PROGRAMS",
      A_GET_STATE_LEADS: "CrmLeadStore/A_GET_STATE_LEADS",
      A_GET_STATUS_LEADS: "CrmLeadStore/A_GET_STATUS_LEADS",
      A_GET_SOURCE_LEADS: "CrmLeadStore/A_GET_SOURCE_LEADS",
      A_GET_SOURCE_NAMES: "CrmGlobalStore/A_GET_SOURCE_NAMES",
      A_GET_STATES: "CrmGlobalStore/A_GET_STATES",
      A_GET_EEUU_STATES: "CrmGlobalStore/A_GET_EEUU_STATES",
      A_GET_COUNTRIES: "CrmGlobalStore/A_GET_COUNTRIES",
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
      SET_PROGRAM: "crm-store/SET_PROGRAM",
      SET_CATCHER: "crm-store/SET_CATCHER",
      SET_SELLER: "crm-store/SET_SELLER",
      A_COUNT_PENDING_WP_CLIENT: "clients-store/A_COUNT_PENDING_WP_CLIENT",
      A_GET_COUNTERS_BY_CLIENT: "RequestNcrStore/A_GET_COUNTERS_BY_CLIENT",
      A_GET_ALL_SERVICES_COUNTER: "clients-store/A_GET_ALL_SERVICES_COUNTER",
    }),
    async getUpdates() {
      const { data } = await ClientsOptionsServices.getCountUpdates({
        accountId: this.$route.params.idClient,
      });
      this.$store.dispatch("ReportStore/A_UPDATES_DISPUTE", data);
    },
    async getPrograms() {
      try {
        await this.A_GET_PROGRAMS();
      } catch (error) {
        console.log("Something went wrong getPrograms", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStateLeads() {
      try {
        await this.A_GET_STATE_LEADS();
      } catch (error) {
        console.log("Something went wrong getStateLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStatusLeads() {
      try {
        await this.A_GET_STATUS_LEADS();
      } catch (error) {
        console.log("Something went wrong getStatusLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceLeads() {
      try {
        await this.A_GET_SOURCE_LEADS();
      } catch (error) {
        console.log("Something went wrong getSourceLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceNames() {
      try {
        await this.A_GET_SOURCE_NAMES();
      } catch (error) {
        console.log("Something went wrong getSourceNames:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStates() {
      try {
        await this.A_GET_STATES({ type: 1 });
      } catch (error) {
        console.log("Something went wrong getStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getEeuuStates() {
      try {
        await this.A_GET_EEUU_STATES();
      } catch (error) {
        console.log("Something went wrong getEeuuStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getCountries() {
      try {
        await this.A_GET_COUNTRIES();
      } catch (error) {
        console.log("Something went wrong getCountries:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },

    async getCounterDispute() {
      try {
        const params = {
          idaccountclient: this.$route.params.idClient,
        };
        const response = await ClientsOptionsServices.counterDispute(params);
        this.countdisputeclient = response.data.length;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with payment days!"
        );
      }
    },
    async getCountFiles() {
      try {
        const params = {
          idaccountclient: this.$route.params.idClient,
        };
        const response = await ClientsOptionsServices.getFileCountClient(
          params
        );
        this.countfileclient = response.data[0].countfile;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with payment days!"
        );
      }
    },

    async getOwners() {
      try {
        const roles = [2, 4].includes(this.moduleId) ? "[1,2,5]" : "[1,2,3,5]";
        await this.A_GET_OWNERS({
          modul: this.moduleId,
          body: { roles, type: "1" },
        });
      } catch (error) {
        console.log("Something went wrong getOwners:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getCounterClaims() {
      this.totalClaims = await ClaimV2Service.countClientClaims({
        client_account_id: this.client.id,
      });
    },

    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async openEditLead() {
      // await all promises

      this.addPreloader();
      await this.getLeadEdit();
      if (this.editSidebar === "first") {
        await Promise.all([
          this.getPrograms(),
          this.getStateLeads(),
          this.getStatusLeads(),
          this.getSourceLeads(),
          this.getSourceNames(),
          this.getStates(),
          this.getEeuuStates(),
          this.getCountries(),
          this.getOwners(),
        ]);
        this.editSidebar = "second";
      }
      if (this.S_LEAD_EDIT) {
        this.isAddUpdateUserSidebarActive = true;
      }
      this.removePreloader();
    },
    async getLeadEdit() {
      try {
        await this.A_GET_LEAD_EDIT({ id: this.client.lead_id });
      } catch (error) {
        console.log("Something went wrong getLeadEdit", error);
      }
    },

    openModalProgramList() {
      this.modalProgramList = true;
    },
    closeModalProgramList() {
      this.modalProgramList = false;
    },
    openModalApp() {
      this.modalApp = true;
    },
    closeModalApp() {
      this.modalApp = false;
    },
    async openTrackingChangePassword() {
      this.activeModalHistoryPassword = true;
    },
    closeModalTrackingAppPassword() {
      this.activeModalHistoryPassword = false;
    },
    openModalTrackingHistoryClaims() {
      this.modalHistoryClaims = true;
    },
    closeModalTrackingHistoryClaims() {
      this.modalHistoryClaims = false;
      this.getCounterClaims();
    },
    openModalCreateNcr(type) {
      this.modalCreateNcr = true;
      this.selectedNcr = type;
    },
    closeModalCreateNcr() {
      this.modalCreateNcr = false;
    },
    async countRequestNcrForUpdate() {
      const { data } = await ClientsOptionsServices.countRequestNcrForUpdate({
        client_account_id: this.$route.params.idClient,
      });
      this.countNcrForUpdate = data.data;
    },
    async openAddService(typeModal) {
      const result = await this.showConfirmSwal(
        typeModal === 3
          ? "Are sure want to add new service?"
          : "Are sure want to change service?"
      );
      if (result.value) {
        this.typeAddChange = 1;
        await this.createEvent();
        const data = {
          lead_name: this.client.client_name,
          lead_id: this.client.lead_id,
          program: this.client.program_name,
        };
        this.modalGeneralService(data, typeModal);
      }
    },
    async createEvent() {
      const response = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: this.client.lead_id,
      });
      this.event = response.id;
    },
    modalGeneralService(data, type) {
      this.detailsClient = data;
      this.typeModal = type;
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },
    async openModalProgram(data) {
      if (data.captured.id && data.program.id && data.seller.id) {
        const response = await this.showConfirmSwal(
          "Are you sure?",
          "You won't be able to revert this!"
        );
        if (response.isConfirmed) {
          await this.addPreloader();
          const param = {
            prices: null,
            observation: "Service",
            contract: 1,
            program: data.program.id,
            fee: null,
            suggested: null,
            otherpricesp: null,
            event: this.event,
            json_noce: null,
            stateid: 0,

            // Diferents to add change Services
            account: this.$route.params.idClient,
            captured: data.captured.id,
            seller: data.seller.id,
            type: this.typeAddChange,
            user_id: this.currentUser.user_id,
            module: this.moduleId,
            id_score: null,
            json_ce: null,
            type_client: "CLIENT",
          };
          try {
            const result = await LeadService.insertSaleMade(param);
            if (result.status == 200) {
              this.modalOpenAddService = false;
              this.$router
                .push({ name: "sales-made-quality-lead" })
                .catch((err) => {
                  console.log(err);
                });
              this.removePreloader();
            }
          } catch (error) {
            this.showErrorSwal(
              "Please, verify if the client already has the service, otherwise contact support.",
              "Error"
            );
          } finally {
            this.removePreloader();
          }
        }
      }
    },
  },
};
</script>

<style>
.content-header .content-header-right button {
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
}

.content-header .content-header-right button.disabled {
  border-color: #82868b !important;
  background-color: #82868b !important;
  cursor: not-allowed;
}

.content-header .content-header-right .b-dropdown button {
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
}

.av-balance-border-blue {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #147ca7;
}

.av-balance-text-blue {
  color: white;
  background-color: #147ca7;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-blue {
  color: white;
  background-color: #0099c8;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.av-balance-border-red {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #e30613;
}
.form-group-input2 {
  padding: 0.438rem 1rem;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
}

.av-balance-text-red {
  color: white;
  background-color: #e30613;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-red {
  color: white;
  background-color: #e83b4c;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.counter-style {
  width: 21px;
  height: 21px;
  background-color: red;
  padding: 3px;
  border-radius: 50%;
  bottom: 20px;
}
</style>
